import { trace, context } from '@opentelemetry/api';
import pino, { Logger } from 'pino';

interface LogData {
  traceId?: string;
  spanId?: string;
  msg?: string;
  [key: string]: any;
}

let baseLogger: Logger | null = null;

function initializeBaseLogger(): Logger {
  try {
    if (baseLogger) return baseLogger;

    baseLogger = pino({
      base: null,
      timestamp: false,
      formatters: {
        bindings: () => ({}),
        level: (label) => ({ level: label }),
        log: (object) => object,
      },
    });
  } catch (error) {
    baseLogger = console as unknown as Logger;
  }
  return baseLogger;
}

function getLogger(additionalContext: Record<string, any> = {}): Logger {
  try {
    const baseLogger = initializeBaseLogger();
    const span = trace.getSpan(context.active());

    const contextData: Record<string, any> = { ...additionalContext };

    if (span) {
      const spanContext = span.spanContext();
      contextData.traceId = spanContext.traceId;
      contextData.spanId = spanContext.spanId;
    }

    const childLogger = baseLogger.child(contextData);

    return {
      info: safeLog(childLogger.info, childLogger),
      error: safeLog(childLogger.error, childLogger),
      debug: safeLog(childLogger.debug, childLogger),
      warn: safeLog(childLogger.warn, childLogger),
    } as Logger;
  } catch (error) {
    return console as unknown as Logger;
  }
}

function safeLog(logFn: (...args: any[]) => void, logger: Logger) {
  return (...args: any[]) => {
    try {
      logFn.apply(logger, args);
    } catch (error) {
      // console.error('Logging error:', error);
    }
  };
}

function formatError(error: unknown) {
  try {
    if (!error) return null;

    if (error instanceof Error) {
      const { name, message, stack } = error;
      return { name, message, stack };
    }

    if (typeof error === 'string') {
      return { msg: error };
    }

    return error;
  } catch (err) {
    // console.error('Error when formatting error:', err);
    return { msg: 'Error in error handling' };
  }
}

function getContextData(additionalContext: Record<string, any> = {}): LogData {
  try {
    const span = trace.getSpan(context.active());
    const spanContext = span?.spanContext();

    return {
      ...additionalContext,
      traceId: spanContext?.traceId,
      spanId: spanContext?.spanId,
    };
  } catch (error) {
    getLogger().error('Error getting context:', error);
    return additionalContext;
  }
}

function logMessage(
  level: 'info' | 'error' | 'debug' | 'warn',
  message: string,
  additionalData: Partial<LogData> = {},
  error?: unknown,
): void {
  try {
    const logger = initializeBaseLogger();
    const duration = performance.now() - (additionalData.startTime || 0);
    const formattedError = formatError(error);

    const logData: LogData = {
      msg: message,
      duration: `${duration.toFixed(2)}ms`,
      ...additionalData,
      ...getContextData(additionalData),
      ...(formattedError && { error: formattedError }),
    };

    safeLog(logger[level], logger)(logData);
  } catch (error) {
    // console.error('Error while logging a message:', error);
  }
}

export class RequestLogger {
  private readonly startTime: number;
  private readonly request: Request;

  constructor(req: Request) {
    this.startTime = performance.now();
    this.request = req;
  }

  private extractHeaders(headers: Headers): Record<string, string | null> {
    try {
      const rawCookies = headers.get('cookie') || '';
      const cookies = Object.fromEntries(
        rawCookies.split(';').map((cookie) => {
          const [key, value] = cookie.trim().split('=');
          return [key, decodeURIComponent(value)];
        }),
      );

      return {
        organizationId: cookies.organizationId || null,
        assistantId: cookies.assistantId || null,
        userId: cookies.userId || null,
      };
    } catch (error) {
      // console.error('Error in header processing:', error);
      return {};
    }
  }

  private log(
    level: 'info' | 'error' | 'debug' | 'warn',
    msgOrStatusCode: string | number,
    msgOrData?: string | LogData,
    data?: LogData,
  ): void {
    try {
      const isMessage = typeof msgOrStatusCode === 'string';
      const msg = isMessage ? msgOrStatusCode : (msgOrData as string);
      const statusCode = isMessage ? undefined : msgOrStatusCode;
      const headers = this.extractHeaders(this.request.headers);
      const additionalData = isMessage ? (msgOrData as LogData) : data;

      const logData: LogData = {
        startTime: this.startTime,
        statusCode,
        organizationId: headers.organizationId,
        assistantId: headers.assistantId,
        userId: headers.userId,
        method: this.request.method,
        url: this.request.url,
        ...additionalData,
      };

      logMessage(level, msg, logData, !isMessage ? additionalData : undefined);
    } catch (error) {
      // console.error('Error in request logging:', error);
    }
  }

  info(msgOrStatusCode: string | number, msgOrData?: string | LogData, data?: LogData): void {
    this.log('info', msgOrStatusCode, msgOrData, data);
  }

  error(msgOrStatusCode: string | number, msgOrData?: string | LogData, data?: any): void {
    this.log('error', msgOrStatusCode, msgOrData, data);
  }

  debug(msgOrStatusCode: string | number, msgOrData?: string | LogData, data?: LogData): void {
    this.log('debug', msgOrStatusCode, msgOrData, data);
  }

  warn(msgOrStatusCode: string | number, msgOrData?: string | LogData, data?: LogData): void {
    this.log('warn', msgOrStatusCode, msgOrData, data);
  }
}

export default getLogger;
